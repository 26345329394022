import React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import {
  getMerchant,
  activateMerchant,
  deactivateMerchant,
  setMerchantSecret,
  inviteUser,
  unlinkMerchantFromParentMerchant,
  updateMerchantContact,
  submitMerchantStyle,
  updateMerchantSettings,
  updateMerchantCurrencies,
  updateMerchantName,
} from '../api'
import { useAuth } from '../auth'
import { isOperator, hasContextContractID } from '../auth-roles'
import MerchantDateTime from './MerchantDateTime'
import { filter, length, map, split, startsWith, trim, flatten } from 'ramda'

import uuid from 'uuid'
import ActiveStatus from '../ActiveStatus'
import MerchantParentOrg from './MerchantParentOrg'
import LinkMerchantToParent from './LinkMerchantToParent'
import MerchantTokens from './MerchantTokens'
import ToggleButton from './../components/ToggleButton'
import { useOrgScope } from '../org-scope'
import OrganisationDescription from './OrganisationDescription'
import MultiSelect from '../components/MultiSelect'
import { currencies } from '../utils/Currencies'

/** ViewMerchant renders a single merchant. */
const ViewMerchant = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const params = useParams()

  // Merchant data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [merchant, setMerchant] = useState()

  const fetchMerchant = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getMerchant(token, params.id)
      setMerchant(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchMerchant()
  }, [params.id])

  return (
    <section className='merchant'>
      <Header />
      <Helmet>
        <title>Merchant {merchant ? merchant.registered_name : ''}</title>
      </Helmet>
      <div className='content'>
        {loading && !merchant && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && merchant && (
          <div className='merchant'>
            <MerchantCore
              merchant={merchant}
              isUserOperator={isUserOperator}
              token={token}
              fetchMerchant={fetchMerchant}
              key={merchant.last_updated_at}
            />
            <MerchantLinks merchant={merchant} />
          </div>
        )}
      </div>
    </section>
  )
}

/** Core / basic merchant details */
const MerchantCore = ({ merchant, isUserOperator, token, fetchMerchant }) => {
  const [error, setError] = useState()
  const [linkParent, setLinkParent] = useState('')
  const [invite, setInvite] = useState(false)
  const [email, setEmail] = useState('')
  const [inviteError, setInviteError] = useState()
  const [message, setMessage] = useState()
  const [editMode, setEditMode] = useState(false)
  const [hostedPaymentPagesState, setHostedPaymentPagesState] = useState(false)
  const [redirectFlowState, setRedirectFlowState] = useState(false)
  const [brandingVisibleState, setBrandingVisibleState] = useState(true)
  const [merchantStyleJsonState, setMerchantStyleJsonState] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [brands, setBrands] = useState([])
  const [merchantName, setMerchantName] = useState('')
  const [merchantTradingName, setMerchantTradingName] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [updatedEmailList, setUpdatedEmailList] = useState(
    merchant.contact_uris.map((x) => ({
      old: x.substring(x.indexOf(':') + 1),
      newEmail: x.substring(x.indexOf(':') + 1),
    }))
  )
  const { byID } = useOrgScope()

  const findChildMerchants = (byID, merchant) => {
    const merchantItem = byID[merchant.id]

    if (merchantItem) {
      setBrands(merchantItem.children ?? [])
    }
  }

  const helperTextDescriptions = {
    background: 'Body background',
    accent: 'Accent color for active borders, buttons etc',
    accentText: 'Text color when the accent is used as a background-color',
    accentHover: 'Button hover color',
    text: 'Normal text color',
    label: 'Input label color',
    input: 'Input color (text inside input boxes)',
    inputBorder: 'Border color when input is not active/focused',
    shadow: 'Button or hover shadow color',
    buttonGreyed: 'Button color when form is invalid',
    buttonGreyedHover: 'Button color when form is invalid and hovered over',
    backgroundImageURL: 'Background image on desktop',
    logoURL: 'Company logo used for desktop',
    mobileBackgroundImageURL: 'Background image on mobile devices',
    mobileLogoURL: 'Company logo used for mobile devices',
    companyAddress: 'Registered address of business',
    companyUrl: 'URL of company website',
  }

  const onActivate = async () => {
    try {
      await activateMerchant(token, merchant.id, '')
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem activating this merchant')
    }
  }

  const onDeactivate = async () => {
    try {
      await deactivateMerchant(token, merchant.id, '')
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem deactivating this merchant')
    }
  }

  const onGenerateSecret = async () => {
    try {
      await setMerchantSecret(token, merchant.id, uuid())
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem activating this merchant')
    }
  }

  const updateStylingObject = (key, value) => {
    merchantStyleJsonState[key] = value
    setMerchantStyleJsonState({ ...merchantStyleJsonState })
  }

  const onInviteButton = async () => {
    setInvite(true)
  }

  const onInviteUser = async () => {
    setInviteError(undefined)
    try {
      await inviteUser(token, merchant.id, email)
      setInvite(false)
      setMessage(`${email} has been sent an email invite`)
      setTimeout(() => {
        setMessage(undefined)
        setEmail(undefined)
      }, 3000)
    } catch (e) {
      setInviteError('There was a problem inviting the user')
    }
  }

  const onLinkParentButton = async (type) => {
    setLinkParent(type)
  }

  const onLinkParentComplete = async () => {
    try {
      await fetchMerchant()
      setLinkParent(false)
    } catch (e) {
      setError('There was a problem retrieving the updated merchant')
    }
  }

  const onUnlinkParent = async () => {
    try {
      if (merchant.part_of_id != undefined) {
        await unlinkMerchantFromParentMerchant(token, merchant.id)
      } else {
        throw new Error('Merchant is not part of a group')
      }
      await fetchMerchant()
    } catch (e) {
      setError('There was a problem unlinking this merchant')
    }
  }

  const updateEmailObject = (old, e) => {
    setUpdatedEmailList([
      ...updatedEmailList.filter((email) => email.old !== old),
      { old, newEmail: e.target.value },
    ])
  }

  const getEmailsForUpdate = () => {
    const chunked = flatten(map((x) => split(',', x.newEmail), updatedEmailList))
    const clean = map((x) => trim(x), chunked)
    const valid = filter((x) => x != '', clean)

    const formatted = map((x) => (startsWith('mailto:', x) ? x : `mailto:${x}`), valid)
    return formatted
  }

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false

    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    for (let item of set1) {
      if (set2.has(item)) {
        set2.delete(item)
      } else {
        return false
      }
    }

    return set2.size === 0
  }

  const updateContactInfo = async () => {
    const newEmails = getEmailsForUpdate()
    try {
      if (!arraysEqual(selectedOptions, merchant.processing_currencies)) {
        let defaultCurrency = selectedOptions.includes('EUR') ? 'EUR' : selectedOptions[0]
        await updateMerchantCurrencies(token, selectedOptions, defaultCurrency, merchant.id)
      }
      await updateMerchantContact(token, newEmails, merchant.id)
      await submitMerchantStyle(token, merchant.id, merchantStyleJsonState)

      if (merchantName !== merchant.registered_name) {
        setShowConfirmation(true)
      } else if (
        merchantName === merchant.registered_name &&
        merchantTradingName !== merchant.trading_as
      ) {
        renameMerchant()
      } else {
        await fetchMerchant()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renameMerchant = async () => {
    try {
      await updateMerchantName(token, merchantName, merchantTradingName, merchant.id)
      await fetchMerchant()
    } catch (error) {
      console.log(error)
    }
  }

  const handleHostedPaymentsStateChange = async (event) => {
    try {
      await updateMerchantSettings(token, merchant.id, event.target.checked, redirectFlowState)
      await fetchMerchant()
    } catch (error) {
      console.log(error)
    }
  }

  const handleRedirectFlowStateChange = async (event) => {
    try {
      await updateMerchantSettings(
        token,
        merchant.id,
        hostedPaymentPagesState,
        event.target.checked
      )
      await fetchMerchant()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setHostedPaymentPagesState(merchant?.settings?.hosted_payments_active)
    setRedirectFlowState(merchant?.settings?.flag_redirect_only_sale)
    if (merchant?.style_customisation) {
      setBrandingVisibleState(true)
      setMerchantStyleJsonState(merchant?.style_customisation)
    }
    setMerchantTradingName(merchant.trading_as)
    setMerchantName(merchant.registered_name)
  }, [merchant])

  useEffect(() => {
    if (byID && merchant) {
      findChildMerchants(byID, merchant)
    }
  }, [byID, merchant])

  useEffect(() => {
    setSelectedOptions(merchant.processing_currencies)
  }, [merchant.processing_currencies])

  return (
    <div className='merchant-core'>
      <div className='merchant-header-container'>
        <h1>
          <i className='fas fa-store' /> {merchant.registered_name || '(un-named merchant)'}{' '}
          <ActiveStatus {...merchant} />
        </h1>
        {!editMode && (
          <div className='merchant-header-button-container'>
            <button
              className='merchant-header-action-button'
              onClick={() => setEditMode(!editMode)}
            >
              <i className='fa fa-edit' />
              Edit
            </button>
          </div>
        )}
        {editMode && (
          <div className='merchant-header-button-container'>
            <button className='merchant-header-action-button' onClick={() => updateContactInfo()}>
              <i className='fas fa-copy' />
              Save
            </button>
            <button
              className='merchant-header-action-button-cancel'
              onClick={() => setEditMode(!editMode)}
            >
              <i className='fas fa-ban' />
              Cancel
            </button>
          </div>
        )}
      </div>
      {isUserOperator && merchant.active && inviteError && (
        <span className={'error'}>{inviteError}</span>
      )}
      {isUserOperator && merchant.active && message && <span className={'info'}>{message}</span>}
      <table>
        <tbody>
          <tr className='merchant_id id'>
            <td className='key'>ID</td>
            <td className='val'>
              <span className='id'>{merchant.id}</span>
            </td>
          </tr>
          <tr className='merchant_name'>
            <td className='key'>Registered Name</td>
            <td className='val'>
              {editMode ? (
                <input
                  type='text'
                  value={merchantName}
                  onChange={(e) => setMerchantName(e.target.value)}
                />
              ) : (
                merchantName || '(un-named merchant)'
              )}
            </td>
          </tr>
          {merchant.created_at && (
            <tr className='created_at'>
              <td className='key'>Created at</td>
              <td className='val'>
                <MerchantDateTime at={merchant.created_at} />
              </td>
            </tr>
          )}
          {merchant.last_updated_at && (
            <tr className='last_updated_at'>
              <td className='key'>Last Updated At</td>
              <td className='val'>
                <MerchantDateTime at={merchant.last_updated_at} />
              </td>
            </tr>
          )}
          {merchant.contact_uris && length(merchant.contact_uris) !== 0 && (
            <tr className='contact_uri'>
              <td className='key'>Contact</td>
              <td className='val'>
                {map((x) => {
                  const email = x.substring(x.indexOf(':') + 1)

                  if (editMode) {
                    return (
                      <div key={x} className='merchant-contact-input-container'>
                        <input
                          onChange={(e) => updateEmailObject(email, e)}
                          type='text'
                          style={{ width: '220px' }}
                          value={
                            updatedEmailList.find((searchEmail) => searchEmail?.old === email)
                              ?.newEmail
                          }
                        ></input>
                      </div>
                    )
                  } else {
                    return (
                      <div key={x}>
                        <a href={x}>{email}</a>
                      </div>
                    )
                  }
                }, merchant.contact_uris)}
              </td>
            </tr>
          )}
          {merchant.trading_as && (
            <tr className='trading_as'>
              <td className='key'>Trading As</td>
              <td className='val'>
                {editMode ? (
                  <input
                    type='text'
                    value={merchantTradingName}
                    onChange={(e) => setMerchantTradingName(e.target.value)}
                  />
                ) : (
                  merchantName
                )}
              </td>
            </tr>
          )}
          {merchant.organisation_id && (
            <tr>
              <td className='key'>Organisation</td>
              <td className='val'>
                <OrganisationDescription id={merchant.organisation_id} />
                {isUserOperator && !merchant.part_of_id && (
                  <button disabled={linkParent} onClick={() => onLinkParentButton('organisation')}>
                    <i className='fas fa-link' /> Change
                  </button>
                )}
              </td>
            </tr>
          )}
          {merchant.part_of_id && (
            <tr>
              <td className='key'>Group Merchant</td>
              <td className='val'>
                <MerchantParentOrg
                  contract_id={merchant.contract_id}
                  part_of_id={merchant.part_of_id}
                />
                {isUserOperator && (
                  <button
                    disabled={linkParent}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to unlink?')) {
                        onUnlinkParent()
                      }
                    }}
                  >
                    <i className='fas fa-link' /> Un-link
                  </button>
                )}
              </td>
            </tr>
          )}
          {isUserOperator && !merchant.part_of_id && brands?.length == 0 && (
            <tr className='parent-org'>
              <td className='key'>Link to a Group Merchant</td>
              <td className='val'>
                <span> </span>
                <button disabled={linkParent} onClick={() => onLinkParentButton('merchant')}>
                  <i className='fas fa-link' /> Link
                </button>
              </td>
            </tr>
          )}
          {brands?.length > 0 && (
            <tr>
              <td className='key'>Brands</td>
              <td className='val'>
                <div className='val-brands'>
                  {brands.map((brand) => (
                    <React.Fragment key={brand.name}>
                      <Link to={`/merchant/${brand.id}`}>{brand.name}</Link>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </td>
            </tr>
          )}
          <tr className='status'>
            <td className='key'>Status</td>
            <td className='val'>
              <ActiveStatus {...merchant} />
              {isUserOperator && merchant.active && (
                <button onClick={onDeactivate}>
                  <i className='fas fa-ban' /> Suspend
                </button>
              )}
              {isUserOperator && !merchant.active && (
                <button onClick={onActivate}>
                  <i className='fas fa-thumbs-up' /> Activate
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td>Redirect Flow</td>
            <td>
              <ToggleButton
                toggleButtonStatus={redirectFlowState}
                handleChange={handleRedirectFlowStateChange}
                disabledState={!editMode}
              />
            </td>
          </tr>
          <tr>
            <td>Hosted Payment Pages</td>
            <td>
              <ToggleButton
                toggleButtonStatus={hostedPaymentPagesState}
                handleChange={handleHostedPaymentsStateChange}
                disabledState={!editMode}
              />
            </td>
          </tr>
          {hostedPaymentPagesState && (
            <>
              <tr className='merchant-branding'>
                <td colSpan={5} onClick={() => setBrandingVisibleState(!brandingVisibleState)}>
                  Styling
                  <i
                    className={`fa fa-chevron-down ${
                      brandingVisibleState ? 'rotated-0' : 'rotated-180'
                    } merchant-branding-toggle`}
                  />
                </td>
              </tr>
              {brandingVisibleState && (
                <tr>
                  <td colSpan={5}>
                    <table>
                      <tbody>
                        {merchantStyleJsonState &&
                          Object?.entries(merchantStyleJsonState).map(([key, value], i) => (
                            <tr key={i}>
                              <td style={{ width: '5%' }}>{key}</td>
                              <td style={{ width: '5%' }}>
                                <input
                                  value={value}
                                  disabled={!editMode}
                                  onChange={(e) => updateStylingObject(key, e.target.value)}
                                ></input>
                              </td>
                              <td>
                                {key !== 'backgroundImageURL' &&
                                  key !== 'mobileBackgroundImageURL' &&
                                  key !== 'logoURL' &&
                                  key !== 'mobileLogoURL' &&
                                  key !== 'companyAddress' &&
                                  key !== 'companyUrl' && (
                                    <input
                                      value={value}
                                      disabled={!editMode}
                                      onChange={(e) => updateStylingObject(key, e.target.value)}
                                      type='color'
                                    />
                                  )}
                              </td>
                              <td>
                                <p style={{ margin: '0px' }}>{helperTextDescriptions[key]}</p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </>
          )}
          {merchant.secret && (
            <tr className='secret'>
              <td className='key'>Secret</td>
              <td className='val'>
                {merchant.secret}
                {isUserOperator && (
                  <button onClick={onGenerateSecret}>
                    <i className='fas fa-lock' /> {(merchant.secret && 'Reset') || 'Generate'}{' '}
                    secret
                  </button>
                )}
              </td>
            </tr>
          )}
          <tr className='merchant-currencies'>
            <td className='key'>Processing Currencies</td>
            <td>
              {editMode ? (
                <MultiSelect
                  options={currencies}
                  selectedOptions={selectedOptions}
                  onChange={setSelectedOptions}
                />
              ) : (
                (merchant.processing_currencies ?? []).join(', ')
              )}
            </td>
          </tr>
          <tr className='merchant-default-currency'>
            <td className='key'>Default Currency</td>
            <td className='val'>{merchant.default_currency}</td>
          </tr>
        </tbody>
      </table>
      <footer className='actions'>
        {isUserOperator && merchant.active && !invite && (
          <button onClick={onInviteButton}>
            <i className='fas fa-user-plus' /> Invite a user
          </button>
        )}
        {isUserOperator && merchant.active && invite && (
          <span>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Email address'}
              autoFocus={true}
            />
            <button onClick={onInviteUser}>Send invite</button>
          </span>
        )}
        {isUserOperator && linkParent && (
          <LinkMerchantToParent
            type={linkParent}
            merchant={merchant}
            onSuccess={() => onLinkParentComplete()}
            onCancel={() => setLinkParent(false)}
          />
        )}
        {error && <div className='error'>{error}</div>}
      </footer>
      {isUserOperator && (
        <MerchantTokens merchant_id={merchant?.id} tokens={merchant.tokens || []} />
      )}
      {showConfirmation && (
        <>
          <div className='overlay'></div>
          <div className='confirmation-dialog'>
            <p>
              <b>Please make sure routing rules align with new merchant name.</b>
            </p>
            <br />
            <p>
              {merchant.registered_name} &rarr; {merchantName}
            </p>
            <button
              onClick={async () => {
                await renameMerchant()
                setShowConfirmation(false)
                setEditMode(false)
                await fetchMerchant()
              }}
            >
              Confirm
            </button>
            <button
              onClick={async () => {
                setShowConfirmation(false)
                setEditMode(false)
                await fetchMerchant()
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  )
}

MerchantCore.propTypes = {
  merchant: PropTypes.object.isRequired,
  isUserOperator: PropTypes.bool,
  token: PropTypes.string,
  fetchMerchant: PropTypes.func.isRequired,
}

const MerchantLinks = ({ merchant }) => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)
  return (
    <div className='useful-links'>
      <h1>
        <i className='fas fa-link' /> Quick links
      </h1>
      <nav>
        <Link to={`/transactions?from=m30d&to=now&merchant_any=${merchant.id}`}>
          <i className='fas fa-th-list' />
          Transactions
        </Link>
        {(isUserOperator || isContractUser) && (
          <Link to={`/settings/allowdeny/${merchant.id}`}>
            <i className='fas fa-ban' />
            Allow / Deny configuration
          </Link>
        )}
      </nav>
    </div>
  )
}
MerchantLinks.propTypes = {
  merchant: PropTypes.object.isRequired,
}

export default ViewMerchant
