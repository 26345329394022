import Header from '../Header'
import Helmet from 'react-helmet'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import KycOwnerSelect from './KycOwnerSelect'
import { getKycOwners } from '../api'
import { useAuth } from '../auth'
import { uploadKYCAnswers } from '../api'
import { countries } from 'country-data'
import ToggleButton from '../components/ToggleButton'
import {
  monthlyFrequencyOfUseList,
  mainSourceOfFundsList,
  monthlyTransactionAmountList,
  monthlyAmountList,
  ReasonsOfUse,
} from './utils/utils'

const KYCQuestion = () => {
  const [email, setEmail] = useState('')
  const [source, setSource] = useState('Back Office')
  const [owners, setOwners] = useState({ owners: [] })
  const [owner, setOwner] = useState('')
  const [countryArray, setCountryArray] = useState([])
  const params = useParams()
  const { token } = useAuth()

  const initialFormState = {
    country_residence: '',
    politically_exposed: false,
    using_on_behalf: false,
    usage_reason: 'Make payments',
    est_monthly_transact_frequency: '6 - 15 times',
    monthly_transaction_amount: '0 - 350 EUR',
    main_source_of_funds: 'Salary',
    monthly_income: '0 - 2000 EUR',
  }

  const [formData, setFormData] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState({})

  const validateForm = () => {
    let errors = {}
    if (!formData.usage_reason) errors.usage_reason = 'Reason for usage is required.'
    if (!formData.country_residence) errors.country_residence = 'Country of residence is required.'
    if (formData.politically_exposed)
      errors.politically_exposed = 'Politically exposed persons cannot be accepted.'
    return errors
  }

  const validateEmail = () => {
    let errors = {}
    if (!email) {
      errors.email = 'Email is required.'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid.'
    }
    return errors
  }

  const validateSource = () => {
    let errors = {}
    if (!source) {
      errors.source = 'Source is required.'
    }
    return errors
  }

  const errorMessage = (key) => {
    return (
      <tr>
        <td></td>
        <td className='kyc-error-message'>
          {formErrors?.[key] && <span>{formErrors[key]}</span>}
          {!formErrors?.[key] && <span style={{ visibility: 'hidden' }}>No Error</span>}
        </td>
      </tr>
    )
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormErrors({})
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleEmailChange = (email) => {
    setFormErrors({})
    setEmail(email)
  }

  const handleSourceChange = (source) => {
    setFormErrors({})
    setSource(source)
  }

  const handleToggle = (name, e) => {
    const { checked } = e.target
    setFormErrors({})
    setFormData({
      ...formData,
      [name]: checked,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const emailErrors = validateEmail()
    const formErrors = validateForm()
    const sourceErrors = validateSource()

    if (
      Object.keys(emailErrors).length === 0 &&
      Object.keys(formErrors).length === 0 &&
      Object.keys(sourceErrors).length === 0
    ) {
      const requestObject = {
        source: source,
        question_profile: 'quickbit-1',
        answers: {
          ...formData,
        },
      }
      try {
        const response = await uploadKYCAnswers(token, owner, email, requestObject)
        console.log(response)
      } catch (error) {
        console.error('Error uploading KYC answers:', error)
      }
    } else {
      setFormErrors({ ...emailErrors, ...formErrors })
    }
  }

  const loadKycOwners = async () => {
    let data = await getKycOwners(token)
    data = data.toSorted((a, b) => a.name.localeCompare(b.name))
    if (data.length > 0) {
      setOwners({ owners: data })
    }
  }

  useEffect(() => {
    loadKycOwners()
    const sortedCountryArray = Object.values(countries.all).sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    setCountryArray(sortedCountryArray)
  }, [])

  useEffect(() => {
    setOwner(params.owner)
  }, [params?.owner])

  return (
    <section className='list-kyc-section'>
      <Header />
      <Helmet>
        <title>Know Your Customer</title>
      </Helmet>
      <div className='profile-kyc-wrapper'>
        <div className='profile-kyc-header-container'>
          <KycOwnerSelect owner={params?.owner} owners={owners.owners} />
        </div>
        <form onSubmit={handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Email</strong>
                  </span>
                </td>
                <td>
                  <input
                    className='kyc-input'
                    name='email'
                    type='email'
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    placeholder='Email'
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className='kyc-error-message'>
                  {formErrors?.email && <span>{formErrors.email}</span>}
                  {!formErrors?.email && <span style={{ visibility: 'hidden' }}>No Error</span>}
                </td>
              </tr>
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Source</strong>
                  </span>
                </td>
                <td>
                  <input
                    className='kyc-input'
                    name='source'
                    type='text'
                    value={source}
                    onChange={(e) => handleSourceChange(e.target.value)}
                    placeholder='source'
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className='kyc-error-message'>
                  {source?.source && <span>{formErrors.source}</span>}
                  {!formErrors?.source && <span style={{ visibility: 'hidden' }}>No Error</span>}
                </td>
              </tr>
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Country of Residence</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='country_residence'
                    type='text'
                    value={formData.country_residence}
                    onChange={handleChange}
                    placeholder='Country of Residence'
                  >
                    <option value=''></option>
                    {countryArray.map((source) => (
                      <option key={`key=${source.alpha2}-${source.name}`} value={source.alpha2}>
                        {source.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('country_residence')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Reason for Using the Service</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='usage_reason'
                    type='text'
                    value={formData.usage_reason}
                    onChange={handleChange}
                    placeholder='Reason for Using the Service'
                  >
                    {ReasonsOfUse.map((source) => (
                      <option key={source.label} value={source.value}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('usage_reason')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Estimated Monthly Transaction Frequency</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='est_monthly_transact_frequency'
                    type='text'
                    value={formData.est_monthly_transact_frequency}
                    onChange={handleChange}
                    placeholder='6 - 15 times'
                  >
                    {monthlyFrequencyOfUseList.map((source) => (
                      <option key={source.label} value={source.value}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('est_monthly_transact_frequency')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Monthly Transaction Amount</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='monthly_transaction_amount'
                    type='text'
                    value={formData.monthly_transaction_amount}
                    onChange={handleChange}
                    placeholder='0 - 350 EUR'
                  >
                    {monthlyTransactionAmountList.map((source) => (
                      <option key={source.label} value={source.value}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('monthly_transaction_amount')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Main Source of Funds</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='main_source_of_funds'
                    type='text'
                    value={formData.main_source_of_funds}
                    onChange={handleChange}
                    placeholder='Savings'
                  >
                    {mainSourceOfFundsList.map((source) => (
                      <option key={source.label} value={source.value}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('main_source_of_funds')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Monthly Income</strong>
                  </span>
                </td>
                <td>
                  <select
                    className='kyc-input'
                    name='monthly_income'
                    type='text'
                    value={formData.monthly_income}
                    onChange={handleChange}
                    placeholder='More than 5000 EUR'
                  >
                    {monthlyAmountList.map((source) => (
                      <option key={source.label} value={source.value}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {errorMessage('monthly_income')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Politically Exposed</strong>
                  </span>
                </td>
                <td>
                  <ToggleButton
                    toggleButtonStatus={formData.politically_exposed}
                    handleChange={(e) => handleToggle('politically_exposed', e)}
                  />
                </td>
              </tr>
              {errorMessage('politically_exposed')}
              <tr>
                <td className='kyc-wide-item'>
                  <span>
                    <strong>Using On Behalf</strong>
                  </span>
                </td>
                <td>
                  <ToggleButton
                    toggleButtonStatus={formData.using_on_behalf}
                    handleChange={(e) => handleToggle('using_on_behalf', e)}
                  />
                </td>
              </tr>
              {errorMessage('using_on_behalf')}
            </tbody>
          </table>
          <button className='kyc-profile-button' type='submit'>
            Submit
          </button>
        </form>
      </div>
    </section>
  )
}

export default KYCQuestion
