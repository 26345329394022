import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useAuth } from './auth'
import { useTheme } from './theme'
import { GetToolbarIcon } from './toolbarHelper'
import {
  isOperator,
  hasContextContractID,
  isControllerNoMerchant,
  isController,
  isKYC,
  isRulesAdmin,
  isMintMyne,
  hasRefundAccess,
} from './auth-roles'
import { getRulesOrganisations } from './routing-rules/RoutingRules'
import { useOrgScope } from './org-scope'

const getInitialRoutingRuleset = (isUserOperator, isOrgLoading, byID, roles) => {
  if (isUserOperator) {
    return 'routing-prologue'
  }

  if (!isOrgLoading && roles) {
    const rulesOrg = getRulesOrganisations(isUserOperator, byID, roles)
    if (rulesOrg.length > 0) {
      return `routing-${rulesOrg[0].id}`
    }
  }

  return ''
}

/** Top-level header with navigation elements. */
const Header = ({ children }) => {
  const theme = useTheme()
  const history = useHistory()
  const { token, roles, email } = useAuth()
  const isUserOperator = isOperator(roles)
  const isUserRulesAdmin = isRulesAdmin(roles)
  const isContractUser = hasContextContractID(roles)
  const isControllerNoMer = isControllerNoMerchant(roles)
  const isUserController = isController(roles)
  const isUserKYC = isKYC(roles)
  const isUserRefunds = isUserOperator || hasRefundAccess(roles)
  const isUserMintMyne = isMintMyne(email)

  const { loading, byID } = useOrgScope()

  // which routing rules does the user have access to?
  const routingRuleset = getInitialRoutingRuleset(isUserOperator, loading, byID, roles)

  const [toggleSideMenu, setToggleSideMenu] = useState(false)

  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const desktopHeaderMenu = () => {
    return (
      <nav>
        {(isUserOperator || isControllerNoMer || isUserController) && (
          <NavLink to='/dashboard?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'dashboard')}
            <span className='text'>Dashboard</span>
          </NavLink>
        )}

        <NavLink to='/transactions?from=m24h&to=now'>
          {GetToolbarIcon(theme.id, 'transactions')}
          <span className='text'>Transactions</span>
        </NavLink>
        {isUserRefunds && (
          <NavLink to='/refunds?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'refunds')}
            <span className='text'>Refunds</span>
          </NavLink>
        )}
        {(isUserOperator || isControllerNoMer || isUserController) && (
          <NavLink to='/recons?from=m24h&to=now&recon_status_any=not_found,mismatch_amount,mismatch_currency,mismatch_status'>
            {GetToolbarIcon(theme.id, 'recon')}
            <span className='text'>Recons</span>
          </NavLink>
        )}

        {(isUserOperator || isUserKYC) && (
          <NavLink to='/know-your-customer'>
            {GetToolbarIcon(theme.id, 'kyc')}
            <span className='text'>KYC</span>
          </NavLink>
        )}

        {isUserOperator && (
          <NavLink to='/merchants'>
            {GetToolbarIcon(theme.id, 'merchants')}
            <span className='text'>Merchants</span>
          </NavLink>
        )}
        {isUserOperator && (
          <NavLink to='/users'>
            {GetToolbarIcon(theme.id, 'users')}
            <span className='text'>Users</span>
          </NavLink>
        )}
        {(isUserRulesAdmin || isUserOperator) && (
          <NavLink
            isActive={(_, location) => {
              return location.pathname.startsWith('/routing-rules')
            }}
            disabled={routingRuleset === ''}
            to={routingRuleset === '' ? '/' : `/routing-rules/${routingRuleset}/current`}
          >
            {GetToolbarIcon(theme.id, 'rules')}
            <span className='text'>Rules</span>
          </NavLink>
        )}
        {(isUserOperator || isContractUser) && !isControllerNoMer && (
          <NavLink to='/settings'>
            {GetToolbarIcon(theme.id, 'settings')}
            <span className='text'>Settings</span>
          </NavLink>
        )}
        <NavLink to='/support'>
          {GetToolbarIcon(theme.id, 'support')}

          <span className='text'>Support</span>
        </NavLink>
        {token && (
          <NavLink to='/account'>
            {GetToolbarIcon(theme.id, 'account')}

            <span className='text'>Account</span>
          </NavLink>
        )}
      </nav>
    )
  }

  const mobileHeaderMenu = () => {
    return (
      <div onClick={() => setToggleSideMenu(!toggleSideMenu)}>
        {GetToolbarIcon(theme.id, 'mobile-menu')}
      </div>
    )
  }
  const SideMenu = ({ toggleSideMenu }) => {
    return (
      <div
        className={`${
          toggleSideMenu ? 'side-menu-open' : 'side-menu-closed'
        } side-menu theme-header-background`}
      >
        <div className='sidemenu-header'>
          <span onClick={() => setToggleSideMenu(!toggleSideMenu)}>X</span>
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/dashboard?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'dashboard')}
            <span className='text'>Dashboard</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/transactions?from=m24h&to=now'>
            {GetToolbarIcon(theme.id, 'transactions')}
            <span className='text'>Transactions</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          {(isUserOperator || isControllerNoMer) && (
            <NavLink to='/know-your-customer'>
              {GetToolbarIcon(theme.id, 'kyc')}
              <span className='text'>KYC</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {isUserOperator && (
            <NavLink to='/merchants'>
              {GetToolbarIcon(theme.id, 'merchants')}
              <span className='text'>Merchants</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {isUserOperator && (
            <NavLink to='/users'>
              {GetToolbarIcon(theme.id, 'users')}
              <span className='text'>Users</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {(isUserRulesAdmin || isUserOperator) && (
            <NavLink to='/routing-rules'>
              {GetToolbarIcon(theme.id, 'rules')}
              <span className='text'>Rules</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          {(isUserOperator || isContractUser) && !isControllerNoMer && (
            <NavLink to='/settings'>
              {GetToolbarIcon(theme.id, 'settings')}
              <span className='text'>Settings</span>
            </NavLink>
          )}
        </div>
        <div className='sidemenu-item-container'>
          <NavLink to='/support'>
            {GetToolbarIcon(theme.id, 'support')}

            <span className='text'>Support</span>
          </NavLink>
        </div>
        <div className='sidemenu-item-container'>
          {token && (
            <NavLink to='/account'>
              {GetToolbarIcon(theme.id, 'account')}

              <span className='text'>Account</span>
            </NavLink>
          )}
        </div>
      </div>
    )
  }

  const goHome = () => history.push('/')
  return (
    <>
      <header className='theme-header-background'>
        {isMobile && mobileHeaderMenu(setToggleSideMenu)}
        {!children && <span className='logo theme-logo' onClick={goHome}></span>}
        {children}
        {!isMobile && desktopHeaderMenu()}
        {!children && isUserMintMyne && (
          <span className='logo theme-logo-mintmyne' onClick={goHome}></span>
        )}
      </header>
      <div className='theme-header-border'></div>
      <SideMenu toggleSideMenu={toggleSideMenu} />
    </>
  )
}
Header.propTypes = {
  children: PropTypes.any,
}

export default Header
