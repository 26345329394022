import { useState, useEffect } from 'react'
import { getTransactions } from '../api'
import { useAuth } from '../auth'
import {
  isOperator,
  isController,
  hideMerchants,
  isPureMerchantFromContract,
  isMintMyne,
} from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import { path, pathOr, slice, is, equals, filter as Rfilter } from 'ramda'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { parseTransactionFilters, updateSearchParamsWithFilters } from './filters'
import Country from '../Country'
import TxnStatus from './TxnStatus'
import TxnID from './TxnID'
import DateTime from '../DateTime'
import DownloadCSVLink from './DownloadCSVLink'
import Header from '../Header'
import Footer from '../Footer'
import AmountWithCurrency from '../AmountWithCurrency'
import Helmet from 'react-helmet'
import TxnsDashboardHeader from './TxnsDashboardHeader'

/* Filters */
import TxnIDFilter from './filters/TxnIDFilter'
import PANFilter from './filters/PANFilter'
import StatusFilter from './filters/StatusFilter'
import TimeFilter from '../filters/TimeFilter'
import CountryFilter from './filters/CountryFilter'
import PSPFilter from './filters/PSPFilter'
import PSPMIDFilter from './filters/PSPMIDFilter'
import MerchantFilter from './filters/MerchantFilter'
import MinorCodeFilter from './filters/MinorCodeFilter'
import { useOrgScope } from '../org-scope'
import FtdFilter from './filters/FtdFilter'
import TxnEmail from './TxnEmail'
import EmailFilter from './filters/EmailFilter'
import { DateTime as DT } from 'luxon'

const LIVE_UPDATE_INTERVAL = 10000 // ms
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7 // ms

/** ListTxns renders a high-level transaction listing which displays a
 * filtered, paginated list of transactions. */
const ListTxns = () => {
  // User auth
  const { token, roles, email } = useAuth()
  const { byID } = useOrgScope()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles)
  const isUserHideMerchants = hideMerchants(roles)
  const isShowFtdSelector = !isPureMerchantFromContract('01FMMJA020VKKAC983HAFZC5DF', roles, byID) // These merchants are not shown the FTD selector
  const isUserMintMyne = isMintMyne(email)

  const dt = DT.local()
  const timezone = dt.toFormat('ZZZZ')

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txns, setTxns] = useState()

  // Interface UI
  const [showMIDs, setShowMIDs] = useState(false)

  // Parse parameters into filter object (which drives filters UI state)
  // Note: We are using the URL as the "state" here, and updates
  // perform a history replacement (so as not to mess up "back" button
  // behaviour too much - though we may want to apply a heuristic like
  // small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // We either poll continuously in the background, or not,
  // depending on user setting. Defaulted true for "base" filter only.
  const [updateLive, setUpdateLive] = useState(
    equals(
      Rfilter((x) => x && !is(Array, x), filter),
      { from: 'm24h', to: 'now' }
    )
  )

  // How we fetch transactions from the back-end
  const fetchTxns = async () => {
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransactions(token, { email, filter: prepareFiltersForGet(filter) })
      result.results = result.results.map(applyAbandonedStatus)
      setTxns(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  const prepareFiltersForGet = (filters) => {
    return filters
  }

  // We only allow update live if date filter is less than 7 days
  const filtersIsUpdateFriendly = () => {
    if (filter.from == 'm1h' && filter.to == 'now') return true
    if (filter.from == 'm24h' && filter.to == 'now') return true
    if (filter.from == 'm7d' && filter.to == 'now') return true
    if (filter.from == 'm30d' && filter.to == 'now') return true
    return false
  }

  const applyAbandonedStatus = (txn) => {
    if (txn.status == 'failed' && txn.failure_code == 22) {
      txn.status = 'abandoned'
    }

    return txn
  }

  // Short-term solution until we have proper pagination: This
  // powers a "Show xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreTxns = async (page) => {
    setUpdateLive(false) // To 'freeze' current view - TODO: Might cause re-fetch....
    setLoading(true)
    setFailed(false)
    try {
      const addTxns = await getTransactions(token, {
        email,
        filter: prepareFiltersForGet(filter),
        page,
      })
      // Append data. Note: because we are paging using the after param we don't get stats back.
      // This is fine since they shouldn't have changed anyway!
      const mappedWithAbandoned = addTxns.results.map(applyAbandonedStatus)
      const newTxns = { ...txns, results: [...txns.results, ...mappedWithAbandoned] }
      setTxns(newTxns)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  // How many more txns are available to load?
  const loadableCount = Math.min(
    100,
    txns && txns.results && txns.results.length && txns.page && txns.page.total_count
      ? txns.page.total_count - txns.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      txns && txns.results && txns.results.length
        ? txns.results[txns.results.length - 1].transaction_id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreTxns(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    setUpdateLive(false)
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch transactions: Initially, and every time filters change
  useEffect(() => {
    fetchTxns()
  }, [urlParams.toString()])

  // Then, poll these same results at an interval, based on whether "Update live" is checked
  // As a hack, for non-live updates, set a long (but non-infinite, which breaks) interval, e.g. a week

  useInterval(
    () => {
      if (filtersIsUpdateFriendly()) {
        fetchTxns()
      }
    },
    updateLive ? LIVE_UPDATE_INTERVAL : ONE_WEEK,
    false
  )

  // Every time 'Update live' is toggled to true, fetch txns
  const handleToggleUpdateLive = (e) => {
    setUpdateLive(e.target.checked)
    if (e.target.checked) {
      fetchTxns()
    }
  }

  return (
    <section className='transactions'>
      <Header />
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <div className='content'>
        {loading && !txns && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txns && txns.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>{txns.results.length.toLocaleString()}</strong>
                {txns.page && txns.results.length !== txns.page.total_count && (
                  <span className='total_count_transaction_header'>
                    of
                    <strong className='count total_count'>
                      {txns.page.total_count.toLocaleString()}
                    </strong>
                  </span>
                )}
                {loading && <span className='loading'>updating...</span>}
              </span>
              {isShowFtdSelector ? (
                <span className='ftds'>
                  <label>FTDs:&nbsp;</label>
                  <FtdFilter filter={filter} setFilter={setFilter} />
                </span>
              ) : null}
              <span className='update-live'>
                <label>
                  <input
                    type='checkbox'
                    checked={filtersIsUpdateFriendly() && updateLive}
                    onChange={handleToggleUpdateLive}
                    disabled={!filtersIsUpdateFriendly()}
                  />
                  Update live
                </label>
              </span>
              <DownloadCSVLink displayCount={path(['page', 'total_count'], txns)} filter={filter} />
            </header>
            <TxnsDashboardHeader
              merchantByID={byID}
              stats={txns.stats}
              txns={txns.results}
              filter={filter}
              setFilter={setFilter}
            />
            <table className='txns'>
              <thead>
                <tr className='table-filter-row'>
                  {/* {!updateLive && <th className='num'>#</th>} */}
                  <th className='time'>
                    <h6>Time ({timezone})</h6>
                    <TimeFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='country'>
                    <h6>Country</h6>
                    <CountryFilter filter={filter} setFilter={setFilter} />
                  </th>
                  {!isUserHideMerchants && !isUserMintMyne && (
                    <th className='merchant'>
                      <h6>Merchant</h6>
                      {byID && (
                        <MerchantFilter filter={filter} setFilter={setFilter} orgsByID={byID} />
                      )}
                    </th>
                  )}

                  {(isUserOperator || isUserController || isUserHideMerchants) && (
                    <th>
                      <div>
                        <h6>
                          PSP{' '}
                          {isUserOperator ? (
                            <span
                              className='clickable psp-mid-toggle'
                              onClick={() => setShowMIDs(!showMIDs)}
                            >
                              <i className={showMIDs ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                            </span>
                          ) : null}
                        </h6>
                        <PSPFilter filter={filter} setFilter={setFilter} />
                      </div>
                      {!isUserMintMyne && JSON.stringify(filter.psp_any) !== '[]' && (
                        <div className='psp-mids-wrapper'>
                          <h6>PSP MIDs</h6>
                          <PSPMIDFilter filter={filter} setFilter={setFilter} />
                        </div>
                      )}
                    </th>
                  )}

                  <th className='id'>
                    <h6>ID</h6>
                    <TxnIDFilter
                      filter={filter}
                      setFilter={setFilter}
                      isOperatorOrController={isUserOperator || isUserHideMerchants}
                    />
                  </th>
                  <th className='status'>
                    <h6>Status</h6>
                    <StatusFilter filter={filter} setFilter={setFilter} />
                    {JSON.stringify(filter.status_any) !== '[]' && (
                      <>
                        &nbsp;
                        <MinorCodeFilter filter={filter} setFilter={setFilter} />
                      </>
                    )}
                  </th>
                  <th className='totreq'>
                    <h6>Amount</h6>
                  </th>
                  <th className='pan'>
                    <h6>Card</h6>
                    <PANFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='email-header'>
                    <h6>Email</h6>
                    <EmailFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='actions' />
                </tr>
              </thead>
              <tbody>
                {txns.results.map((txn) => {
                  const detailURI = `/transaction/${txn.transaction_id}`
                  return (
                    <tr className='txn' key={txn.transaction_id}>
                      {/*
                    <tr className='txn clickable' key={txn.transaction_id}>
                      onClick={() => history.push(detailURI)}*/}
                      {/* {!updateLive && <td className='num'>{i + 1}.</td>} */}
                      <td className='time clickable' onClick={() => history.push(detailURI)}>
                        <DateTime at={txn.created_at} />
                      </td>
                      <td className='country'>
                        <Country iso={path(['country_code'], txn)} />
                      </td>
                      {!isUserHideMerchants && !isUserMintMyne && (
                        <td className='merchant'>{txn.merchant_name || '-'}</td>
                      )}
                      {(isUserOperator || isUserController || isUserHideMerchants) && (
                        <td className='psp-id'>
                          {(showMIDs ? txn.psp_credential_id ?? txn.psp_id : txn.psp_id) ?? '-'}
                        </td>
                      )}

                      <td className='id'>
                        <TxnID transaction_id={txn.transaction_id} order_ref={txn.order_ref} />
                      </td>
                      <td className='status'>
                        <TxnStatus {...txn} />
                      </td>
                      <td className='totreq'>
                        <AmountWithCurrency {...txn.total_requested} />
                      </td>
                      {/* In case of bad / long value, truncate for now */}
                      <td className='pan'>{slice(0, 20, pathOr('-', ['card', 'bin'], txn))}**</td>
                      <td>
                        <TxnEmail {...txn?.sale_request?.card_holder}></TxnEmail>
                      </td>
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {txns.page && txns.results.length !== txns.page.total_count && (
                <>
                  <button onClick={handleLoadMore} disabled={loading && !updateLive}>
                    {loading && !updateLive ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                  {' or '}
                </>
              )}
              <DownloadCSVLink displayCount={path(['page', 'total_count'], txns)} filter={filter} />
            </footer>
          </div>
        )}
        {!failed && txns && txns.results && txns.results.length === 0 && (
          <div className='no-results'>There are no transactions to show</div>
        )}
        <Footer />
      </div>
    </section>
  )
}

export default ListTxns
